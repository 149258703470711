<template>
  <div class="sessionOverview">
    <div
      v-if="$parent"
      class="d-none d-md-block container ml-0"
    >
      <button
        v-if="runsAvailable && filteredRuns.some(x => x.selected) && authenticationHasRole('moderation')"
        class="btn btn-danger btn-sm moveToTabsRmv"
        @click="removeSelected()"
      >
        <span>Delete Selected</span>
      </button>
      <template v-if="filter && $parent.useAlternativeFilter == false">
        <div class="row">
          <div>
            <div class="d-inline-block mb-3 mr-3">
              <label>{{ $t('from') }} - {{ $t('until') }}</label>
              <date-picker
                v-model="filter.dates"
                :shortcuts="shortcuts"
                :editable="true"
                :not-after="new Date()"
                :clearable="false"
                range
                confirm
                range-separator="-"
                type="date"
                lang="en"
                format="DD.MM.YYYY HH:mm"
                class="w-100"
                value-type="date"
                @input="updateDates()"
                @input-error="catchInputError"
              />
            </div>

            <div class="d-inline-block mb-3 mr-3">
              <label>{{ $t('limit') }}</label>
              <select
                v-model="filter.limit"
                class="form-control"
              >
                <option :value="null">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option :value="100">
                  100
                </option>
                <option :value="200">
                  200
                </option>
                <option :value="500">
                  500
                </option>
                <option :value="1000">
                  1000
                </option>
                <option :value="5000">
                  5000
                </option>
              </select>
            </div>

            <div class="d-inline-block mb-3 mr-3">
              <label>{{ $t('filter') }}</label>
              <br>
              <toggle-button
                v-model="filter.showIncomplete"
                :labels="{ checked: 'show incomplete', unchecked: 'hide incomplete' }"
                :width="150"
                :height="40"
                :font-size="12"
                class="m-0"
                @change="updateSessions()"
              />
            </div>

            <div class="d-inline-block mb-3 mr-3">
              <label>{{ $t('cardNumber') }}</label>
              <input
                v-model="filter.cardNumber"
                class="form-control"
              >
            </div>

            <div
              v-if="installationType == 'skimovie' && !authenticationHasRole('zerolevel')"
              class="d-inline-block mb-3 mr-3"
            >
              <label>{{ $t('runRating') }}</label>
              <select
                v-model="filter.finalState"
                class="form-control"
              >
                <option :value="null">
                  {{ $t('pleaseSelectOne') }}
                </option>
                <option :value="'Unknown'">
                  Unknown
                </option>
                <option :value="'Timing Mode'">
                  Timing Mode
                </option>
                <option :value="'Good'">
                  Good
                </option>
                <option :value="'Medium'">
                  Medium
                </option>
                <option :value="'Bad'">
                  Bad
                </option>
              </select>
            </div>
            <template v-if="installationType == 'skimovie' && !authenticationHasRole('zerolevel')">
              <div>
                <div class="form-check form-check-inline mb-3 mr-5">
                  <input
                    id="useState"
                    v-model="useStateFilter"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="useState"
                  >Use State Filter</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="raceStartedCheckbox"
                    v-model="filter.raceStarted"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="raceStartedCheckbox"
                  >RaceStarted</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="raceFinishedCheckbox"
                    v-model="filter.raceFinished"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="raceFinishedCheckbox"
                  >SessionFinished</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="objectCaughtCheckbox"
                    v-model="filter.objectCaught"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="objectCaughtCheckbox"
                  >ObjectCaught</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="objectLostCheckbox"
                    v-model="filter.objectLost"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="objectLostCheckbox"
                  >ObjectLost</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="timeoutCheckbox"
                    v-model="filter.timeout"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="timeoutCheckbox"
                  >Timeout</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="isFaultyCheckbox"
                    v-model="filter.isFaulty"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="isFaultyCheckbox"
                  >IsFaulty</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="timingModeCheckbox"
                    v-model="filter.timingMode"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="timingModeCheckbox"
                  >TimingMode</label>
                </div>
                <div class="form-check form-check-inline mb-3">
                  <input
                    id="virtualStopwatchCheckbox"
                    v-model="filter.virtualStopwatch"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label"
                    for="virtualStopwatchCheckbox"
                  >VirtualStopwatch</label>
                </div>
              </div>
            </template>
          </div>
          <div class="d-inline-block mb-3 mr-3 mt-4">
            <label class="d-block w-100">&nbsp;</label>
            <button
              class="btn btn-primary btn-block mb-3"
              @click="checkFilterForLoading()"
            >
              <font-awesome-icon
                class="mr-2"
                icon="search"
              />
              <span>{{ $t('search') }}</span>
            </button>
            <div class="clearfix" />
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm moveToTabs"
          @click="removeFilter()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="minus"
            style="vertical-align: middle;"
          />
          Remove filter
        </button>
      </template>
      <template v-else>
        <button
          class="btn btn-primary btn-sm moveToTabs"
          @click="toggleFilter()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="filter"
          />
          <span>Change filter</span>
        </button>
      </template>
    </div>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="runsAvailable">
        <span v-if="filter && filteredRunsAvailable">Total: {{ filteredRuns.length }}</span>
        <div
          v-if="filter && filteredRunsAvailable"
          class="sessionsPreview"
        >
          <template v-for="(run, index) in filteredRuns">
            <div
              :key="`skimovieSessions-runDiv-${ index }`"
              style="display: inline-flex;"
              class="sessionPreview float-left"
            >
              <span
                v-if="isSkimovie && !authenticationHasRole('scope_customer')"
                v-tooltip.left="{
                  content: getSkimovieTooltipText(run),
                  classes: 'pre-line-tltp' 
                }"
                class="mb-2 rounded-left"
                :style="`border-left: 4px ${ getSkimovieLine(run) } ${ getSkimovieColorCode(run) } !important`"
              />
              <div
                v-if="index <= filter.limit - 1"
                :class="['p-2 border mb-2 mr-2 alt-pointer text-center', {'rounded-right': isSkimovie, 'rounded': !isSkimovie}]"
                @click="$parent.openModal(run)"
              >
                <input
                  v-if="authenticationHasRole('moderation')"
                  v-model="run.selected"
                  type="checkbox"
                  class="float-right mb-2"                  
                  @click.stop
                >
                <div class="imagePlaceholderWrapper rounded mb-1">
                  <img
                    v-if="getPreviewImage(run)"
                    :src="getPreviewImage(run)"
                    class="previewImage rounded"
                    loading="lazy"
                  >
                  <ImagePlaceholderSvg
                    v-else
                    :height="108"
                    :width="192"
                    class="previewImage rounded w-100 h-100"
                  />
                  <div
                    v-if="displayProperties.includes('duration')"
                    class="itemDuration rounded-left-top"
                  >
                    <span v-if="run.duration">{{ run.duration }}s</span>
                  </div>
                </div>
                <div
                  v-if="displayProperties.includes('createDate')"
                  v-tooltip="{ content: dateTime_fromNow(run.createDate), position: 'top' }"
                >
                  <label class="bold alt-pointer mr-2 mb-0">{{ $t('created') }}:</label>
                  <span v-if="run.createDate">{{ dateTime_dateTimeSeconds(run.createDate) }}</span>
                  <span v-else>{{ $t('noDataAvailable') }}</span>
                </div>
                <div
                  v-if="displayProperties.includes('spotId')"
                >
                  <label class="bold alt-pointer mr-2 mb-0">SpotId:</label>
                  <span v-if="run.createDate">{{ run.spotId }}</span>
                  <span v-else>{{ $t('noDataAvailable') }}</span>
                </div>
                <div
                  v-if="displayProperties.includes('speed')"
                  class="itemInfo"
                >
                  <label class="bold alt-pointer mr-2">{{ $t('speed') }}:</label>
                  <span v-if="run.speed">{{ run.speed }} <sup>km</sup>/<sub>h</sub></span>
                  <span v-else>{{ $t('noDataAvailable') }}</span>
                </div>
              </div>
            </div>
          </template>
          <div class="clearfix" />
        </div>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
    <SweetModal
      ref="confirmDelete"
      :title="$t('deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToDeleteMedia', {'0' : filteredRuns.filter(x => x.selected).length}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeRuns()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'SessionOverview',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    DatePicker,
    SweetModal
  },
  mixins: [
    authenticationMixin,
    dateTimeMixin
  ],
  props: {
    runs: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    filter: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    displayProperties: {
      type: Array,
      required: false,
      default () {
        return [ 'createDate' ]
      }
    },
    installationType: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: false,
      default: ""
    },
    isChinaInstallation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      filteredRuns: null,
      originalFilter: null,
      useStateFilter: false,
      shortcuts: [
        {
          text: 'Today',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        },
        {
          text: 'Yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        },
        {
          text: 'The day before yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 3, 0, 0, 0);
            this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 23, 59, 59);
            this.filter.dates = [];
            this.filter.dates.push(this.filter.startDate, this.filter.endDate);
            this.updateSessions();
          }
        }
      ]
    }
  },
  computed: {
    isSkimovie () {
      return this.installationType == "skimovie";
    },
    runsAvailable () {
      if (this.runs == null) {
        return false;
      }
      if (this.runs.length == null) {
        return false;
      }
      if (this.runs.length == 0) {
        return false;
      }
      return true;
    },
    filteredRunsAvailable () {
      if (this.filteredRuns == null) {
        return false;
      }
      if (this.filteredRuns.length == null) {
        return false;
      }
      if (this.filteredRuns.length == 0) {
        return false;
      }
      return true;
    }
  },
  watch: {
    runs () {
      this.updateSessions();
    }
  },
  created () {
    this.updateSessions();
    this.originalFilter = JSON.parse(JSON.stringify(this.filter));
    window.setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    removeSelected () {
      this.$refs.confirmDelete.open();
    },
    removeRuns () {
      var selected = this.filteredRuns.filter(x => x.selected);
      var ids = selected.map(x => x.id);
      var reqObj = {};
      if(this.installationType == "photopoint") {
        reqObj.SkilineIds = ids;
      }
      else {
        reqObj.SkimovieIds = ids;
      }
      this.$refs.confirmDelete.close();
      this.loading = true;
      this.axios.post(`/Media/RemoveSessions?installationType=${this.installationType}&installationId=${this.installationId}`, reqObj)
        .then((response) => {
          this.$snotify.success(response.data);
        })
        .finally(() => {
          this.loadSessions();
        });
    },
    getSkimovieTooltipText (run) {
      if(this.isSkimovie && !this.authenticationHasRole('zerolevel')) {
        return "raceStarted: " + run.raceStarted + '\n' +
        "raceFinished: " + run.raceFinished + '\n' +
        "objectCaught: " + run.objectCaught + '\n' +
        "objectLost: " + run.objectLost + '\n' +
        "timeout: " + run.timeout + '\n' +
        "isFaulty: " + run.isFaulty + '\n' +
        "timingMode: " + run.timingMode + '\n' +
        "virtualStopwatch: " + run.virtualStopwatch;
      }
      return null;
    },
    getSkimovieColorCode (run) {
      if (run.finalState == "Unknown")
      {
          return "grey"; //unknown
      }
      else if (run.finalState == "Timing Mode")
      {
          return "blue"; //timingmode
      }
      else if (run.finalState == "Good")
      {
          return "green"; //good
      }
      else if (run.finalState == "Medium")
      {
          return "orange"; //medium
      }
      else if (run.finalState == "Bad")
      {
          return "red"; //bad
      }
      return "orange"; 
    },
    getSkimovieLine (run) {
      if (run.virtualStopwatch)
      {
          return "dashed"; 
      }
      return "solid"; 
    },
    toggleFilter () {
      this.$parent.useAlternativeFilter = !this.$parent.useAlternativeFilter;
    },
    removeFilter () {
      this.$parent.filter.cardNumber = null;

      if(this.installationType == "skimovie") {
        this.$parent.filter.finalState = null;
      }
      
      this.$parent.useAlternativeFilter = !this.$parent.useAlternativeFilter;
      this.loadSessions();
    },
    splitDate (date) {
      let dateParts = date.split('.');
      dateParts.push(dateParts[2].split(' ')[1].split(':')[0], dateParts[2].split(' ')[1].split(':')[1]);
      dateParts[2] = dateParts[2].split(' ')[0];
      return dateParts;
    },
    catchInputError (error) {
      let index = error.indexOf('-');

      let firstDate = error.substring(0, index - 1);
      let secondDate = error.substring(index + 2, error.length);

      let firstDateParts = this.splitDate(firstDate);
      let secondDateParts = this.splitDate(secondDate);
      
      firstDate = new Date(firstDateParts[2], firstDateParts[1] - 1, firstDateParts[0], firstDateParts[3], firstDateParts[4]);
      secondDate = new Date(secondDateParts[2], secondDateParts[1] - 1, secondDateParts[0], secondDateParts[3], secondDateParts[4]);

      if (isNaN(firstDate.getTime())) {
        return;
      }
      if (isNaN(secondDate.getTime())) {
        return;
      }

      this.filter.dates = [firstDate, secondDate];
      this.updateDates();
    },
    updateDates () {
      if (this.filter == null) {
        this.filteredRuns = [];
        return;
      }
      if (this.filter.dates == null) {
        this.filteredRuns = [];
        return;
      }
      if (this.filter.dates[0] == null) {
        this.filteredRuns = [];
        return;
      }
      if (this.filter.dates[1] == null) {
        this.filteredRuns = [];
        return;
      }
      if (this.filter.dates[1].getHours() == 0 && this.filter.dates[1].getMinutes() == 0) {
        this.filter.dates[1] = new Date(this.filter.dates[1].getFullYear(), this.filter.dates[1].getMonth(), this.filter.dates[1].getDate(), 23, 59);
      }
      this.updateSessions();
    },
    updateSessions () {
      let temporaryRuns = [];
      for (let index = 0; index < this.runs.length; index++) {
        if (this.isInSelection(this.runs[index], index) == true) {
          temporaryRuns.push(this.runs[index]);
        }
      }

      let cTemporaryRuns = [];
      for (let cIndex = 0; cIndex < temporaryRuns.length; cIndex++) {
        if (this.checkCardNumber(temporaryRuns[cIndex], cIndex) == true && this.checkFinalState(temporaryRuns[cIndex], cIndex) == true && this.checkStateFlags(temporaryRuns[cIndex], cIndex) == true) {
          cTemporaryRuns.push(temporaryRuns[cIndex]);
        }
      }

      this.filteredRuns = JSON.parse(JSON.stringify(cTemporaryRuns));
    },
    loadSessions () {
      let payload = {
        dates: this.filter.dates,
        limit: this.filter.limit,
        cardNumber: this.filter.cardNumber
      };
      this.$emit('loadSessions', payload);
      this.originalFilter = JSON.parse(JSON.stringify(this.filter));
      
      this.updateSessions();
    },
    checkFilterForLoading () {
      if (this.filter.limit < this.originalFilter.limit) {
        this.updateSessions();
        if(this.installationType == "photopoint" && this.filter.cardNumber != null && this.filter.cardNumber.length > 0) {
          this.loadSessions();    
        }
        return;
      }
      this.loadSessions();
    },
    getPreviewImage (run) {
      if (run == null) {
        return;
      }

      if(this.isChinaInstallation && run.imageUrl) {
        return run.imageUrl
      }

      if (run.snapshotUrl) {
        return run.snapshotUrl;
      }
      if (run.previewImageUrl) {
        return run.previewImageUrl;
      }
      return;
    },
    checkCardNumber (run) {
      if(this.filter.cardNumber != null && this.filter.cardNumber.length != 0) {
        if(this.installationType == "skimovie") {
          if(run.identificationIds.includes(this.filter.cardNumber)) {
            return true;
          }
        }
        else if(this.installationType == "photopoint") {
          return true;
        }
        else {
          if(run.identificationId == this.filter.cardNumber) {
            return true;
          }
        }

        return false;
      }
      return true;
    },
    checkFinalState (run) {
      if(this.installationType == "skimovie" && !this.authenticationHasRole('zerolevel')) {
        if(this.filter.finalState != null && this.filter.finalState.length > 0) {
          return this.filter.finalState == run.finalState;
        }
      }
    
      return true;
    },
    checkStateFlags (run) {
      if(this.installationType == "skimovie" && this.useStateFilter && !this.authenticationHasRole('zerolevel')) {
        if(this.filter.raceStarted && run.raceStarted) {
            return true;
        }
        
        if(this.filter.raceFinished && run.raceFinished) {
            return true;
        }

        if(this.filter.objectCaught && run.objectCaught) {
            return true;
        }

        if(this.filter.objectLost && run.objectLost) {
            return true;
        }

        if(this.filter.timeout && run.timeout) {
            return true;
        }

        if(this.filter.isFaulty && run.isFaulty) {
            return true;
        }

        if(this.filter.timingMode && run.timingMode) {
            return true;
        }

        if(this.filter.virtualStopwatch && run.virtualStopwatch) {
            return true;
        }

        return false;
      }
    
      return true;
    },
    isInSelection (run) {
      if (run == null) {
        return false;
      }
      if (this.filter == null) {
        return true;
      }
      if (run.createDate == null) {
        return false;
      }
      if (this.filter.dates[0] == null) {
        return false;
      }
      if (this.filter.dates[1] == null) {
        return false;
      }
      let createDate = new Date(run.createDate);
      let startDate = new Date(this.filter.dates[0]);
      let endDate = new Date(this.filter.dates[1]);
      if (this.$parent.useAlternativeFilter == true) {
        return true;
      }
      if (createDate.getTime() < startDate.getTime()) {
        return false;
      }
      if (createDate.getTime() > endDate.getTime()) {
        return false;
      }
      if (this.filter.showIncomplete == true) {
        return true;
      }
      if (run.snapshotUrl == null) {
        return false;
      }
      if (run.snapshotUrl.length == 0) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}
video {
  width: 100%;
  max-height: 45vh;
}
.sessionPreview {
  width: 49%;
  float: left;
  text-align: center;
}
@media (max-width: 991.98px) {
  .sessionPreview:nth-child(odd) {
    margin: 0 2% 2% 0 !important;
  }
  .sessionPreview:nth-child(even) {
    margin: 0 0 2% 0 !important;
  }
}
@media (min-width: 991.98px) {
  .sessionPreview {
    width: 208px;
    float: none;
    text-align: left;
  }
}
.itemInfo {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.itemDuration {
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: bold;
  background: rgba(255,255,255,.5);
  padding: 3px;
}
.imagePlaceholderWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 108px;
}
.imagePlaceholderWrapper > *:not(.itemDuration) {
  position: absolute;
  width: 100%;
  height: auto;
  min-width: auto;
  min-height: 100%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style>
.sessionOverview {
  position: relative;
}
.sessionOverview svg:not(.mx-calendar-icon) rect {
  width: 100% !important;
}
.sessionOverview .moveToTabs {
  position: absolute;
  top: -60px;
  right: 15px;
}

.sessionOverview .moveToTabsRmv {
  position: absolute;
    top: -60px;
    right: 125px;
}

.colored {
  background-color: red;
}

.pre-line-tltp .tooltip-inner {
 white-space: pre; 
}
</style>

